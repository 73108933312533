@-webkit-keyframes slideLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@-webkit-keyframes slideRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@keyframes slideRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

html {
  font-size: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  scroll-behavior: smooth;
}

a,
button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  a,
  button {
    cursor: default !important;
    border: none;
    outline: none;
  }
}

img,
video {
  display: block;
  max-width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}

.menu-container {
  max-width: 80rem;
  width: 100%;
  height: auto;
  padding: 0 2rem;
  margin: 0 auto;
}

@media only screen and (max-width: 992px) {
  .menu-container {
    padding: 0 1rem;
    margin: 0 auto;
  }
}

// .brand {
//   font-family: inherit;
//   font-size: 1.75rem;
//   font-weight: 700;
//   line-height: inherit;
//   color: var(--blue);
//   border: none;
//   outline: none;
//   text-transform: uppercase;
//   text-rendering: optimizeLegibility;
// }

.header {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 10px 0;
  margin: 0 auto;
  z-index: 999;
  border: none;
  outline: none;
  background: var(--dark-blue);
  -webkit-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.header .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header-item-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 17%;
          flex: 0 0 17%;
}

.header-item-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 66%;
          flex: 0 0 66%;
}

.header-item-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 17%;
          flex: 0 0 17%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.header-item-right .menu-icon {
  font-size: 1.35rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 1rem;
  margin-left: 1rem;
  border: none;
  outline: none;
  color: #121212;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.header .menu > ul > li {
  display: inline-block;
  line-height: 3.125rem;
  margin-left: 1.5rem;
}

.header .menu > ul > li > a {
  position: relative;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
  border: none;
  outline: none;
  color: hsl(0, 0%, 100%);
  text-transform: capitalize;
  text-rendering: optimizeLegibility;
  -webkit-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

.header .menu > ul > li .menu-subs {
  position: absolute;
  width: 100%;
  height: auto;
  margin-top: 1.75rem;
  padding: 1rem 2rem;
  border: none;
  outline: none;
  z-index: 500;
  opacity: 0;
  visibility: hidden;
  border-radius: 0.25rem;
  border-top: 3px solid var(--blue);
  background: #ffffff;
  -webkit-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.header .menu > ul > li .menu-subs > ul > li {
  line-height: 1;
}

.header .menu > ul > li .menu-subs > ul > li > a {
  display: inline-block;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
  padding: 0.75rem 0;
  border: none;
  outline: none;
  color: #121212;
  text-transform: capitalize;
  text-rendering: optimizeLegibility;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header .menu > ul > li .menu-column-1 {
  min-width: 16rem;
  max-width: 20rem;
}

.header .menu > ul > li .menu-subs.menu-mega {
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}

.header .menu > ul > li .menu-subs.menu-mega > .list-item > ul > li {
  display: block;
  line-height: 1;
}

.header .menu > ul > li .menu-subs.menu-mega > .list-item > ul > li > a {
  display: inline-block;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
  padding: 0.5rem 0;
  border: none;
  outline: none;
  color: #121212;
  text-transform: capitalize;
  text-rendering: optimizeLegibility;
  -webkit-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

.header .menu > ul > li .menu-subs.menu-column-4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-width: 68rem;
  width: 100%;
  padding: 1.25rem 1rem;
}

.header .menu > ul > li .menu-subs.menu-column-4 > .list-item {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  padding: 0 1rem;
}

.header .menu > ul > li .menu-subs.menu-column-4 > .list-item .title {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  padding: 0.75rem 0;
  border: none;
  outline: none;
  color: var(--blue);
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header .menu > ul > li .menu-subs.menu-column-4 > .list-item.text-center .title {
  text-align: center;
}

.header .menu > ul > li .menu-subs.menu-column-4 > .list-item img.responsive {
  max-width: 100%;
  width: 100%;
  height: auto;
  margin-top: 0.75rem;
  -o-object-fit: cover;
     object-fit: cover;
  vertical-align: middle;
}

.header .menu > ul > li .menu-subs.menu-mega > .list-item > ul > li > a:hover,
.header .menu > ul > li .menu-subs > ul > li > a:hover {
  color: var(--blue);
}

.header-item-right a:hover,
.header .menu > ul > li:hover > a {
  color: var(--light-green);
}

@media only screen and (min-width: 993px) {
  .header .menu > ul > li.menu-item-has-children:hover .menu-subs {
    margin-top: 0.5rem;
    opacity: 1;
    visibility: visible;
  }
}

.menu-mobile-header,
.menu-mobile-trigger {
  display: none;
}

@media only screen and (max-width: 992px) {
  .header-item-center {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .header-item-left, .header-item-right {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
  }
  .wrapper {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .header .menu-mobile-trigger {
    position: relative;
    display: block;
    cursor: pointer;
    width: 1.75rem;
    height: 1rem;
    border: none;
    outline: none;
    margin-left: 1.25rem;
    background: none;
    -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: 0.35s ease-in-out;
    -o-transition: 0.35s ease-in-out;
    transition: 0.35s ease-in-out;
  }
  .header .menu-mobile-trigger span {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    border: none;
    outline: none;
    opacity: 1;
    border-radius: 0.25rem;
    background: #121212;
    -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  .header .menu-mobile-trigger span:nth-child(1) {
    top: 0;
  }
  .header .menu-mobile-trigger span:nth-child(2), .header .menu-mobile-trigger span:nth-child(3) {
    top: 0.5rem;
  }
  .header .menu-mobile-trigger span:nth-child(4) {
    top: 1rem;
  }
  .header-item-right {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .header .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 15rem;
    height: 100%;
    z-index: 1099;
    overflow: hidden;
    background: #ffffff;
    -webkit-transform: translate(-100%);
        -ms-transform: translate(-100%);
            transform: translate(-100%);
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
  .header .menu.active {
    -webkit-transform: translate(0%);
        -ms-transform: translate(0%);
            transform: translate(0%);
  }
  .header .menu > ul > li {
    display: block;
    line-height: 1;
    margin: 0;
  }
  .header .menu > ul > li > a {
    display: block;
    line-height: 3.125rem;
    height: 3.125rem;
    padding: 0 3.125rem 0 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .header .menu > ul > li > a i.ion {
    position: absolute;
    font-size: 1.25rem;
    line-height: 3.125rem;
    top: 0;
    right: 0;
    width: 3.125rem;
    height: 3.125rem;
    text-align: center;
    -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .header .menu .menu-mobile-header {
    position: relative;
    position: -webkit-sticky;
    position: sticky;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    top: 0;
    height: 3.125rem;
    z-index: 501;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: #ffffff;
  }
  .header .menu .menu-mobile-header .menu-mobile-arrow {
    display: none;
    font-size: 1.25rem;
    line-height: 3.125rem;
    width: 3.125rem;
    height: 3.125rem;
    cursor: pointer;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    color: #121212;
    text-align: center;
  }
  .header .menu .menu-mobile-header.active .menu-mobile-arrow {
    display: block;
  }
  .header .menu .menu-mobile-header .menu-mobile-title {
    font-family: inherit;
    font-size: 1rem;
    font-weight: 500;
    line-height: inherit;
    color: #121212;
    text-transform: capitalize;
    text-rendering: optimizeLegibility;
  }
  .header .menu .menu-mobile-header .menu-mobile-close {
    font-size: 2.25rem;
    line-height: 3.125rem;
    cursor: pointer;
    width: 3.125rem;
    height: 3.125rem;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    color: #121212;
    text-align: center;
  }
  .header .menu .menu-section {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .header .menu > ul > li .menu-subs {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    max-width: none;
    min-width: auto;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 1rem;
    padding-top: 4rem;
    opacity: 1;
    overflow-y: auto;
    visibility: visible;
    -webkit-transform: translateX(0%);
        -ms-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .header .menu > ul > li .menu-subs.menu-mega {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    max-width: none;
    min-width: auto;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 1rem;
    padding-top: 4rem;
    opacity: 1;
    overflow-y: auto;
    visibility: visible;
    -webkit-transform: translateX(0%);
        -ms-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .header .menu > ul > li .menu-subs.active {
    display: block;
  }
  .header .menu > ul > li .menu-subs.menu-column-4 > .list-item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    padding: 0rem;
  }
  .header .menu > ul > li .menu-subs.menu-column-4 > .list-item img.responsive {
    margin-top: 0;
  }
  .header .menu > ul > li .menu-subs.menu-column-4 > .list-item.text-center .title {
    margin-bottom: 1.25rem;
  }
  .header .menu > ul > li .menu-subs.menu-column-4 > .list-item.text-center:last-child .title {
    margin-bottom: 0rem;
  }
  .header .menu > ul > li .menu-subs > ul > li > a {
    display: block;
  }
  .header .menu > ul > li .menu-subs.menu-mega > .list-item > ul {
    margin-bottom: 1rem;
  }
  .header .menu > ul > li .menu-subs.menu-mega > .list-item > ul > li > a {
    display: block;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1098;
    opacity: 0;
    visibility: hidden;
    background: rgba(0, 0, 0, 0.55);
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
  .overlay.active {
    opacity: 1;
    visibility: visible;
  }
}

.header .adriane-block {
  color: var(--light-green);
  .name {
    color: hsl(0, 0%, 100%);
  }
}

.adriane-block-mobile {
  display: none;

}

@media (max-width: 600px) {
  .header {
    .header-item-left {
      .adriane-block {
        display: none;
      }
      .adriane-block-mobile {
        color: var(--light-green);
        
        display: block;
        text-align: center;
        .name {
          color: hsl(0, 0%, 100%);
          font-size: 24px;
        }
        .phone {
          font-size: 22px;
        }
      }
    }
    .menu-mobile-trigger span {
      background: hsl(0, 0%, 100%);
    }
  }
}

@media only screen and (max-width: 992px) {
  .header .menu >ul >li >a {
      color: black;
  }
}
