.about-photo-and-text {
  display: flex;
  flex-direction: row;
}

.about-photo {
  order: 2;
  padding-left: 10px;
  width: 33%

  img {
    max-width: 350px !important;
  }
}

.about-text {
  display: flex;
  flex-direction: column;
  order: 1;
  width: 66%;
}

@media (max-width: 600px) {
  .about-photo-and-text {
    display: block;
    padding-right: 0px;
  }

  .about-photo {
    width: 100%
  }
  
  .about-text {
    width: 100%;
  }
}
