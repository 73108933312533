/* Base Typo */

body {
  color: var(--text-color);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
  font-weight: 300;
}

h1,
h2,
h3 {
  margin-top: 2rem;
}

h4,
h5,
h6 {
  margin-top: 1.5rem;
}

h1 {
  font-size: 3.6rem;
  line-height: 1.2;
  letter-spacing: -0.1rem;
}

h2 {
  font-size: 3.0rem;
  line-height: 1.25;
  letter-spacing: -0.1rem;
}

h3 {
  font-size: 2.4rem;
  line-height: 1.3;
  letter-spacing: -0.1rem;
}

h4 {
  font-size: 1.8rem;
  line-height: 1.35;
  letter-spacing: -0.08rem;
}

h5 {
  font-size: 1.5rem;
  line-height: 1.5;
  letter-spacing: -0.05rem;
}

h6 {
  font-size: 1.2rem;
  line-height: 1.6;
  letter-spacing: 0;
}

p {
  line-height: 1.5;
  margin: 1rem 0;
}

/* Larger than phablet */

@media (min-width: 600px) {
  h1 {
    font-size: 3.6rem;
  }
  h2 {
    font-size: 3.0rem;
  }
  h3 {
    font-size: 2.4rem;
  }
  h4 {
    font-size: 1.8rem;
  }
  h5 {
    font-size: 1.5rem;
  }
  h6 {
    font-size: 1.2rem;
  }
}

/* Links */

a {
  color: var(--link-color);
}

a:hover {
  color: var(--link-color-hover);
}

/* Colors */
.text-primary {
 color: var(--text-color-primary)
}

.text-success {
  color: var(--text-color-success)
}

.text-danger {
  color: var(--text-color-danger)
}
