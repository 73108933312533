*, *:before, *:after {
  box-sizing: border-box;
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
}

body {
  overflow-x: hidden;
}

a,
button {
  background: none;
  border: none;
  color: steelblue;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
  text-decoration: none;
}

@media only screen and (max-width: 992px) {
  a,
  button {
    cursor: default !important;
    border: none;
    outline: none;
  }
}

img,
video {
  display: block;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  max-width: 100%;
}

// Used on all pages except home
main {
  background: #ffffff;
  height: auto;
  margin: 120px auto 40px;
  max-width: 1200px;
  min-height: calc(100vh-110px);
  padding: 0 20px;
}

// Used on home only
.container {
//   height: auto;
  // margin: 90px auto 20px;
  // width: 100%;
// padding: 10px;
//   border: 1px solid blue;
}

.bs {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(104, 76, 76, 0.3);
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  
  iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  }
}

@media only screen and (max-width: 992px) {
  .container {
    // padding: 0;
    // margin: 0 auto;
  }
}
