/* Beach Cam Link
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.beachcam-link-wrapper {
  border: 1px solid hsl(0, 0%, 0%);
  display: flex;
  margin: 0 auto 40px;
  max-width: 800px;

  .beachcam-text {
    padding: 10px;

    h3 {
      margin-top: 0px;
    }
  }
  .beachcam-photo {
    float: right;
    img {
      max-height: 160px;
    }
  }
}

@media (max-width: 600px) {
  .beachcam-link-wrapper {
    display: grid;
    width: 320px;
  }
}

/* Testimonials
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.testimonials {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  img {
    margin: auto;
    width: 150px;
  }

  .testimonial {
    background: #fff;
    border-radius: 4px;
    border: 1px solid var(--light-gray);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    display: inline-block;
    margin: 1rem;
    min-width: 300px;
    padding: 25px 20px 10px;
    position: relative;
    text-align: left;
    width: 300px;
  }
}



/* Neighborhoods
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.neighborhood-container {
  // border: 1px solid green;
  display: flex;
  height: 125px;

  .photo {
    // border: 1px solid blue;
    width: 66%;
  }

  img {
    height: 125px;
    object-fit: cover;
  }

  .map {
    height: 0;
    overflow: hidden;
    padding-bottom: 125px;
    position: relative;
    width: 34%;

    iframe{
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.neighborhood-description {
  // border: 1px solid black;
  width: 100%
}

@media (max-width: 600px) {
  .neighborhood-container {
    flex-direction: column;

    .map {
      width: 100%;
      height: 125px;
    }

    .photo {
      width: 100%;
      height: 125px;
    }
  }
  .neighborhood-description {
    margin-top: 125px;
  }
}

/* Listed Properties
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#featured > div, #soldLeased > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.places {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  
  .property-card {
    background: #fff;
    border-radius: 4px;
    display: inline-block !important;
    margin: 1rem .3rem;
    padding: 10px;
    visibility: hidden;
    width: 280px;
  
    img {
      height: 195px;
      width: 260px;
    }
  
    .card-body {
      padding: 10px;
    }
  
    h4 {
      font-size: 1.6em;
      font-weight: 400;
      margin-bottom: .3em;
      width: 100%;
    }
  }
}

/* IDX
–––––––––––––––––––––––––––––––––––––––––––––––––– */
// .idx-search-wrapper {
//   margin-top: 40px;
//   text-align: center;
// }

// @media (max-width: 600px) {
//   .idx-search-wrapper {
//     display: none;
//   }
// }


  /* Home Owners Association
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.hoa-table {
  p {
    display: none;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    overflow: hidden;
    
   td, th {
      border-top: 1px solid #ECF0F1;
      padding: 10px;
    }
    
    td {
      border-left: 1px solid #ECF0F1;
      border-right: 1px solid #ECF0F1;
    }
    
    th {
      background-color: var(--blue);
      color: white;
    }
    
    tr:nth-of-type(even) td {
      background-color: var(--light-blue);
    }
    
    .total {
      th {
        background-color: white;
      }
      
      td {
        text-align: right;
        font-weight: 700;
      }
    }
  }
  
    .mobile-header {
      display: none;
    }
}

@media only screen and (max-width: 760px) {
  .hoa-table {
    p {
      display: block;
      font-weight: bold;
    }
    
    table {
      tr {
        td:not(:first-child), th:not(:first-child), td:not(.total-val) {
          display: none;
        }
        
        &:nth-of-type(even) td:first-child {
          background-color: var(--light-blue);
        }
        &:nth-of-type(odd) td:first-child {
          background-color: white;
        }
        
        &:nth-of-type(even) td:not(:first-child) {
          background-color: white;
        }
        
        th:first-child {
          width: 100%;
          display:block;
        }
        
        th:not(:first-child) {
          width: 40%;
          transition: transform 0.4s ease-out;
          transform:translateY(-9999px);
          position: relative;
          z-index: -1;
        }
        
        td:not(:first-child) {
          transition: transform 0.4s ease-out;
          transform:translateY(-9999px);
          width: 60%;
          position: relative;
          z-index: -1;
        }
        
        td:first-child {
          display: block;
          cursor: pointer;
        }
        
        &.total th {
          width: 25%;
          display: inline-block;
        }
        
        td.total-val {
          display: inline-block;
          transform: translateY(0);
          width: 75%;
        }
      }
    }
  }
}

@media only screen and (max-width: 300px) {
  .hoa-table {
    table {
      tr {
        th:not(:first-child) {
          width: 50%;
          font-size: 14px;
        }
        
        td:not(:first-child) {
          width: 50%;
          font-size: 14px;
        }
      }
    }
  }
}

/* Hiking Trails
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.hiking-video {
  margin: auto;
  max-width: 600px;
}
