.hero {
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  min-height: 100vh;
  // opacity: 80%;
  position: relative;
  width: 100%;

  .hero-text {
    color: #fff;
    left: 50%;
    margin: 0 auto;
    max-width: 900px;
    position: absolute;
    text-align: center;
    text-shadow: 1px 1px 1px rgb(75, 75, 75);
    top: 40%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 3;
  }

  h1 {
    font-size: 86px;
    font-weight: 400;
    line-height: 1em;
    margin: 0;
  }

  h2 {
    font-size: 26px;
    font-style: italic;
    font-weight: 400;
    margin-top: 5px;
  }

  /* Search Bar
–––––––––––––––––––––––––––––––––––––––––––––––––– */
  .search-bar {
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: center;
    margin: 30px 0 40px;
    padding-left: 5px;

    input {
      background: white;
      border: none;
      font-weight: 200;
      height: 100%;
    }

    .address-input {
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      padding-left: 20px;
      width: 300px;
    }
    
    .amount-input {
      border-left: 1px solid lightgray;
      padding-left: 12px;
      width: 120px;
    }

    input:focus{
      outline: none;
  }

    button {
      background: hsl(0, 81%, 56%);
      color: white;
      font-weight: 700;
      height: 100%;
      padding-left: 10px;
      width: 170px;
    }

    .triangle {
      border-bottom: 30px solid transparent;
      border-left: 30px solid hsl(0, 81%, 56%);
      border-top: 30px solid transparent;
      height: 0; 
      width: 0; 
    }
  }

  .hero-buttons {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 28px;
    max-width: 500px;

    a {
      align-items: center;
      background: #000;
      border-radius: 5px;
      color: #fff;
      display: flex;
      font-size: 14px;
      height: 60px;
      justify-content: center;
      margin: 0 3px;
      text-transform: uppercase;
      width: 50%;
    }
  }

  /* Mobile
–––––––––––––––––––––––––––––––––––––––––––––––––– */
  @media (max-width: 600px) {
    .hero-text {
      h1 {
        font-size: 58px;
        // margin-top: calc(110px + 5vh);
        margin-top: 160px;
      }

      h2 {
        display: none;
      }
    }

    .search-bar {
      display: block;
      height: 44px;
      margin: 10px 0 160px;
      padding: 0;

      input {
        border-radius: 5px;
        height: 100%;
        margin: 5px;
      }

      button {
        background: hsl(0, 81%, 56%);
        color: white;
        font-weight: 700;
        height: 150px;
        padding-left: 10px;
        width: 170px;
        height: 60px;
      }
  
      .triangle {
        display: none;
      }
    }

    max-width: none;
  }


  /* Carousel
–––––––––––––––––––––––––––––––––––––––––––––––––– */
  #all_slides {
      height: 100vh;
      list-style-type: none;
      margin: 0px;
      padding: 0px;
      position: relative;
  }

  .slide {
      height: 100%;
      left: 0px;
      opacity: 0;
      position: absolute;
      top: 0px;
      transition: opacity 2s;
      width: 100%;
      z-index: 1;
      -moz-transition: opacity 2s;
      -o-transition: opacity 2s;
      -webkit-transition: opacity 2s;
  }

  .active {
      opacity: 1;
      z-index: 2;
  }

  .controls {
      display: none;
  }

  .slide {
      font-size: 40px;
      padding: 40px;
      box-sizing: border-box;
      background: none;
      color: #fff;
      background-size: cover;
  }

  .slide:nth-of-type(1) {
    background-image: url('/images/pacific-palisades-coast-large.jpg');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .slide:nth-of-type(2) {
    background-image: url('/images/interior-large.jpg');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .slide:nth-of-type(3) {
    background-image: url('/images/exterior-large.jpg');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .slide:nth-of-type(4) {
    background-image: url('/images/interior3-large.jpg');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .slide:nth-of-type(5) {
    background-image: url('/images/interior2-large.jpg');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .controls {
    display: inline-block;
    position: relative;
    top: 1rem;
    right: .5rem;
    border: none;
    outline: none;
    font-size: 20px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 1.5rem;
    background: gold;
    width: 3rem;
    height: 3rem;
    margin-left: .5rem;
  }

  .controls:hover,
  .controls:focus {
    background: #eee;
    color: #333;
  }

  .buttons {
    position: absolute;
    right: .5rem;
    top: 0px;
    z-index: 10;
    font-size: 0px;
  }
}
